import React from "react";

const UnderConstructPage = () => {
  return (
    <div className="w-full max-w-full relative overflow-hidden">
      <main className="flex flex-col items-center justify-center z-10 my-20 mx-10">
        <h1>Site is under renovation.</h1>
      </main>
    </div>
  );
};

export default UnderConstructPage;
